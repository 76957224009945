import { Link } from "gatsby";
import React from "react";

import Logo from "../images/best-logo-transparent.png";

function Navbar() {
  return (
    <nav className="flex items-center justify-between h-16 border-b border-gray-200 px-4">
      <Link to="/">
        <div className="flex items-center justify-between flex-wrap">
          <img className="mr-2 h-12" src={Logo} alt="Brown Esports Logo"></img>
          <div>
            <p className="hidden md:block text-xl font-medium">Brown Esports</p>
          </div>
        </div>
      </Link>
      <a
        className="px-4 py-2 font-medium rounded-md shadow-md text-gray-200 bg-gradient-to-br bg-gray-900 hover:bg-gray-700 transition-colors duration-100 ml-3 inline-flex"
        href="https://bit.ly/best-space-petition"
      >
        <div className="flex items-center">
          <span>Sign the Petition</span>
          <svg
            className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
              fillRule="nonzero"
            ></path>
          </svg>
        </div>
      </a>
    </nav>
  );
}

export default Navbar;
